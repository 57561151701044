<template>
    <ul class="header-menu">
        <li class="header-menu__item">
            <a href="" class="header-menu__link">
                О компании
            </a>
        </li>
        <li class="header-menu__item">
            <a href="" class="header-menu__link">
                Структура
            </a>
        </li>
        <li class="header-menu__item">
            <a href="" class="header-menu__link">
                Проекты
            </a>
        </li>
        <li class="header-menu__item">
            <a href="" class="header-menu__link">
                Контакты
            </a>
        </li>
    </ul>
</template>

<script>
  export default {
    name: 'HeaderMenu'
  }
</script>

<style lang="scss">
    .header-menu {
        display: flex;

        &__item {
            margin: 0 30px;
        }

        &__link {
            font-size: 16px;
            font-weight: 500;
            color: #002145;

            transition: color ease-in-out 0.3s;

            text-decoration: none;
            text-transform: uppercase;


            &:hover {
                color: #E3000B;
            }
        }
    }
</style>
