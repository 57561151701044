<template>
  <div class="container">
    <div class="tape">
      <div class="tape__inner">
        <div class="tape__inner-list">
          <div class="tape__inner-list-item">
            900.6 <span>-9.5 (-0.24%)</span>
          </div>
          <div class="tape__inner-list-item">
            NASDAQ 100 12764.2 <span>-27.0 (-0.21%)</span>
          </div>
          <div class="tape__inner-list-item">
            EUR/USD 1.19170 <span>-0.00115 (-0.10%)</span>
          </div>
          <div class="tape__inner-list-item">
            900.6 <span>-9.5 (-0.24%)</span>
          </div>
          <div class="tape__inner-list-item">
            NASDAQ 100 12764.2 <span>-27.0 (-0.21%)</span>
          </div>
          <div class="tape__inner-list-item">
            EUR/USD 1.19170 <span>-0.00115 (-0.10%)</span>
          </div>
          <div class="tape__inner-list-item">
            900.6 <span>-9.5 (-0.24%)</span>
          </div>
          <div class="tape__inner-list-item">
            NASDAQ 100 12764.2 <span>-27.0 (-0.21%)</span>
          </div>
          <div class="tape__inner-list-item">
            EUR/USD 1.19170 <span>-0.00115 (-0.10%)</span>
          </div>
        </div>
      </div>

      <a href="/important-news" class="tape__nav tape__nav--left">
        Important news
      </a>

      <a href="/trading-vieews" class="tape__nav tape__nav--right">
        Trading Views
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tape'
}
</script>

<style lang="scss">
  .tape {
    position: relative;
    z-index: 9;

    padding: 9px 37px;

    max-width: 825px;
    margin: 10px auto 64px;

    background-color: rgb(255 255 255 / 50%);
    border: 1px solid rgb(93 127 143 / 50%);

    &__inner {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;

      &-list {
        display: inline-block;
        padding-left: 100%;
        animation: scroll 30s infinite linear;
        &:hover {
          animation-play-state: paused;
        }

        &-item {
          display: inline-block;
          vertical-align: middle;
          margin: 0 16px;
          font-size: 15px;
          color: #002E45;

          span {
            color: #879BAE;
          }
        }
      }
    }

    &__nav {
      font-size: 10px;
      color: #ffffff;

      top: 50%;
      transform: translateY(-50%);

      padding: 4px 12px;

      position: absolute;
      background-color: rgb(135, 155, 174);

      &--left {
        left: -45px
      }

      &--right {
        right: -45px
      }

      &:hover {
        background-color: rgb(93, 127, 143);
        top: -1px;
        transform: translateY(0);
        padding: 13px 12px 13px;
      }
    }
  }

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0)
    }
  }
</style>
