<template>
  <div class="bg">
    <Header/>
    <Tape/>
    <MainMenu/>

    <div class="bg__particles">
      <Particles
              id="tsparticles"
              :particlesInit="particlesInit"
              :particlesLoaded="particlesLoaded"
              :options="{
                      background: {
                          color: {
                              value: 'transparent'
                          }
                      },
                      fpsLimit: 30,
                      interactivity: {
                          detectsOn: 'canvas',
                          events: {
                              onClick: {
                                  enable: true,
                                  mode: 'push'
                              },
                              onHover: {
                                  enable: true,
                                  mode: 'grab'
                              },
                              resize: true
                          },
                          modes: {
                              grab: {
                                distance: 300
                              },
                              bubble: {
                                  distance: 700,
                                  duration: 5,
                                  opacity: 0.8,
                                  size: 40
                              },
                              push: {
                                  quantity: 4
                              },
                              repulse: {
                                  distance: 200,
                                  duration: 0.4
                              }
                          }
                      },
                      particles: {
                          color: {
                              value: '#437992'
                          },
                          links: {
                              color: '#73b3d0',
                              distance: 150,
                              enable: true,
                              opacity: 0.6,
                              width: 1
                          },
                          collisions: {
                              enable: true
                          },
                          move: {
                              direction: 'none',
                              enable: true,
                              outMode: 'bounce',
                              random: false,
                              speed: 6,
                              straight: false
                          },
                          number: {
                              density: {
                                  enable: true,
                                  value_area: 800
                              },
                              value: 80
                          },
                          opacity: {
                              value: 0.5
                          },
                          shape: {
                              type: 'circle'
                          },
                          size: {
                              random: true,
                              value: 5
                          }
                      },
                      detectRetina: true
                  }"
      />
    </div>

    <AcceptCookie/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Tape from "./components/Tape"
import MainMenu from "./components/MainMenu"
import AcceptCookie from "./components/AcceptCookie"

export default {
  name: 'App',
  components: {
    Header,
    Tape,
    MainMenu,
    AcceptCookie
  },
  methods: {
  }
}
</script>
