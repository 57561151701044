import { createApp } from 'vue'
import Particles from "particles.vue3"
import VueAnimateOnScroll from 'vue-animate-onscroll'
import VueCookies from 'vue3-cookies'
import App from "./App.vue"

import './styles/general.scss'

const app = createApp(App)

app.use(VueAnimateOnScroll)
app.use(Particles)

app.use(VueCookies)

app.mount('#app')
