<template>
  <div class="container">
    <div class="main-menu">
      <div class="main-menu__list main-menu__list--left">
        <a href="/projects" class="main-menu__list-title">деятельность</a>

        <div class="main-menu__list-item" @mouseover="hover = 'left-1'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>Поддержка экспортных поставок</text>
          </a>

          <div class="main-menu__list-item-text">
            <p><HiddenTextIcon/></p>
            <p>Поддержка экспортных поставок. Помощь в организации экспортной деятельности предприятия: сертификация, таможенное администрирование, логистика.</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'left-2'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>Аналитика</text>
            <br>
            <text>и исследования</text>
          </a>

          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'left-3'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>Продвижение<br/>на внешние рынки</text>
          </a>
          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'left-4'" @mouseleave="hover = false">
          <router-link href="/" class="main-menu__list-link">
            <text>Торгово-посреднические операции</text>
          </router-link>
          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
      </div>

      <div class="main-menu__tree" v-bind:class="hover">
        <Tree/>
      </div>

      <div class="main-menu__list main-menu__list--right">
        <a href="/projects" class="main-menu__list-title">проекты</a>

        <div class="main-menu__list-item">
          <a href="/" class="main-menu__list-link" @mouseover="hover = 'right-1'" @mouseleave="hover = false">
            <text>EURASPROJECT<span>аутсорсинг</span></text>
          </a>
          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'right-2'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>LIGHT PROGRESS<span>UF технологии</span></text>
          </a>
          <div class="main-menu__list-item-text">
            <p><HiddenTextIconV2/></p>
            <p>UF технологии от итальянского производителя для муниципального, коммерческого и частных производств</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'right-3'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>HOUSESHARING <span>Прокат Эко-домов</span></text>
          </a>
          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
        <div class="main-menu__list-item" @mouseover="hover = 'right-4'" @mouseleave="hover = false">
          <a href="/" class="main-menu__list-link">
            <text>CofeeStories<span>Кофе премиум</span></text>
          </a>
          <div class="main-menu__list-item-text">
            <p>Раздел находится в разработке</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tree from "../assets/images/tree.svg"

import HiddenTextIcon from "../assets/images/hidden-text-icon.svg"
import HiddenTextIconV2 from "../assets/images/hidden-text-icon-v2.svg"

export default {
  name: 'MainMenu',
  data() {
    return {
      hover: false
    }
  },
  components: {
    Tree,
    HiddenTextIcon,
    HiddenTextIconV2
  }
}
</script>

<style lang="scss">
  .main-menu {
    display: flex;
    align-items: flex-start;

    &__list {
      display: flex;
      flex-direction: column;
      margin: 0 0 258px;

      &--left {
        width: 428px;
        align-items: flex-end;

        .main-menu__list-title {
          margin-right: 24px;
          right: -30px;
        }

        .main-menu__list-item {
          justify-content: flex-end;
          right: -30px;
        }

        .main-menu__list-link {
          transform-origin: right;
          padding: 8% 14% 8% 4%;
          text-align: right;

          &:before {
            background-image: url("/images/main-menu-left-bg.svg");
          }

          &:after {
            background-image: url("/images/main-menu-left-bg-hover.svg");
          }
        }

        .main-menu__list-item-text {
          left: -20%;
        }
      }

      &--right {
        width: 375px;
        align-items: flex-start;

        .main-menu__list-title {
          margin-left: 24px;
          left: -20px;
        }

        .main-menu__list-item {
          justify-content: flex-start;
          // they are not symmetric and have to be aligned
          left: -30px;
        }

        .main-menu__list-link {
          transform-origin: left;
          padding: 8% 4% 8% 24%;
          text-align: left;
          width: 375px;

          &:before {
            background-image: url("/images/main-menu-right-bg.svg");
          }

          &:after {
            background-image: url("/images/main-menu-right-bg-hover.svg");
          }
        }

        .main-menu__list-item-text {
          left: 40%;
        }
      }

      &-title {
        position: relative;
        z-index: 9;

        margin: 0 0 2px;
        padding: 14px 10px 26px;

        font-size: 18px;
        font-weight: 500;
        color: #002145;

        display: block;

        &:after,
        &:before {
          content: "";
          position: absolute;
          width: 12px;
          height: 60px;
          top: -6px;  // FIXME: image size
          transform: scale(0.8);
        }

        &:before {
          background-image: url("/images/menu-title-bg.svg");
          left: 0;
        }

        &:after {
          background-image: url("/images/menu-title-bg.svg");
          right: 0;
          transform: rotate(-180deg) scale(0.8);
        }
      }

      &-item {
        position: relative;

        display: flex;
        align-items: center;

        width: 400px;
        height: 110px;

        &:hover {
          .main-menu__list-link {
            color: #002E45;
            transform: scale(1.2);

            &:before {
              opacity: 0;
            }

            &:after {
              opacity: 1;
            }
          }

          .main-menu__list-item-text {
            opacity: 1;
            z-index: 99;
          }
        }

        /*&:focus,*/
        /*&:active {*/
        /*  .main-menu__list-item-text {*/
        /*    display: none;*/
        /*  }*/
        /*}*/

        &-text {
          position: absolute;
          z-index: -1;
          opacity: 0;
          top: 96%;
          background-color: rgba(23, 33, 60, 0.6);
          width: 320px;
          padding: 16px;
          font-size: 15px;
          color: #ffffff;
          transition: opacity 0.2s ease-in;
          line-height: 1.3;

          p {
            margin-bottom: 6px;
          }
        }
      }

      &-link {
        position: absolute;
        z-index: 9;
        display: block;

        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(0, 46, 69, 0.5);

        width: 100%;

        transition: transform 0.2s ease-in;
        will-change: transform;

        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          background-position: center;
          background-repeat: no-repeat;
          transition: opacity 0.2s ease-in;
        }

        &:after {
          opacity: 0;
        }

        text {
          position: relative;
          z-index: 3;
        }

        span {
          position: relative;
          z-index: 3;
          display: block;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }

    &__tree {
      position: relative;
      width: 392px;
      padding: 45px 0 0 0;

      svg {
        width: 400px;
        max-width: 400px;
        transform: scale(0.9);
      }

      &.left-1 {
        .left-1-stroke {
          stroke: #E3000B;
        }

        .left-1-fill {
          fill: #E3000B;
        }
      }

      &.left-2 {
        .left-2-stroke {
          stroke: #E3000B;
        }

        .left-2-fill {
          fill: #E3000B;
        }
      }

      &.left-3 {
        .left-3-stroke {
          stroke: #E3000B;
        }

        .left-3-fill {
          fill: #E3000B;
        }
      }

      &.left-4 {
        .left-4-stroke {
          stroke: #E3000B;
        }

        .left-4-fill {
          fill: #E3000B;
        }
      }

      &.right-1 {
        .right-1-stroke {
          stroke: #E3000B;
        }

        .right-1-fill {
          fill: #E3000B;
        }
      }

      &.right-2 {
        .right-2-stroke {
          stroke: #E3000B;
        }

        .right-2-fill {
          fill: #E3000B;
        }
      }

      &.right-3 {
        .right-3-stroke {
          stroke: #E3000B;
        }

        .right-3-fill {
          fill: #E3000B;
        }
      }

      &.right-4 {
        .right-4-stroke {
          stroke: #E3000B;
        }

        .right-4-fill {
          fill: #E3000B;
        }
      }
    }

    .tree-stroke {
      stroke: #ffffff;
      transition: all 0.2s ease-in;
    }

    .tree-fill {
      fill: #ffffff;
      transition: all 0.2s ease-in;
    }
  }
</style>
