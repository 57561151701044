<template>
  <div class="container">
    <header class="header">
      <div class="header__logo">
        <Logo/>
      </div>
      <div class="header__menu">
        <HeaderMenu/>
      </div>
      <div class="header__nav">
        <div class="header__nav-lang header__nav-lang--active-left">
          <Lang/>

          <a href="#url" class="header__nav-lang-item header__nav-lang-item--active">Ru</a>
          <span>/</span>
          <a href="#url" class="header__nav-lang-item">EN</a>
        </div>

        <div class="header__nav-list">
          <div class="header__nav-list-item header__nav-list-item--search">
            <a href="#search">
              <Search/>
            </a>
          </div>
          <div class="header__nav-list-item header__nav-list-item--register">
            <a href="#register">Зарегистрироваться</a>
            <a href="#auth">Войти</a>
          </div>
          <div class="header__nav-list-item header__nav-list-item--url">
            <a href="#url">url</a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import HeaderMenu from './HeaderMenu.vue'
import Logo from '../assets/images/logo.svg';
import Search from '../assets/images/search.svg';
import Lang from '../assets/images/header-lang-bg.svg';

export default {
  name: 'Header',
  components: {
    Logo,
    Search,
    Lang,

    HeaderMenu
  }
}
</script>

<style lang="scss">
  .header {
    position: relative;
    z-index: 9;

    display: flex;
    justify-content: space-between;

    padding: 5px 0 0;

    &__menu {
      align-self: flex-end;
      margin: 0 0 32px;
    }

    &__logo {
      margin: 45px 0 0;
      width: 262px;

      align-self: flex-end;

      svg {
        width: 236px;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-list {
        display: flex;
        justify-content: flex-end;
        width: 262px;

        &-item {
          font-size: 8px;
          font-weight: 700;

          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;

          height: 32px;
          text-align: center;

          &--search {
            background-image: url("/images/header-nav-bg.svg");
            width: 58px;
            line-height: 32px;
            margin-right: 15px;

            svg {
              vertical-align: middle;
              height: 23.5px;
            }
          }

          &--register {
            background-image: url("/images/header-nav-bg-v2.svg");
            width: 116px;
            margin-right: 15px;
            text-transform: uppercase;

            padding: 6px 0;

            a {
              margin: 2px 0;
            }
          }

          &--url {
            background-image: url("/images/header-nav-bg-v3.svg");
            width: 58px;
            line-height: 32px;
            text-transform: uppercase;

            a {
              font-size: 14px;
            }
          }

          a {
            display: block;
            color: #002145;
          }
        }
      }

      &-lang {
        margin: 0 0 22px;

        position: relative;

        width: 83px;
        height: 37px;

        line-height: 35px;
        text-align: center;

        span {
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px;
        }

        svg {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;

          path {
            fill: #002145;
            stroke: #002145;
          }
        }

        &-item {
          position: relative;
          z-index: 2;
          font-size: 14px;
          font-weight: 700;
          color: #002145;

          &--active {
            color: #E63027;
          }
        }

        &--active-left {
          .header__lang-item--left {
            fill: #E63027;
            stroke: #E63027;
          }
        }

        &--active-right {
          .header__lang-item--right {
            fill: #E63027;
            stroke: #E63027;
          }
        }
      }
    }
  }

  .cls-1 {
    fill:#002e45;
  }

  .cls-2 {
    fill:#437992;
  }
</style>
