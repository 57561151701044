<template>
  <div v-bind:class="[{ 'accept-cookie--hidden': hasCookies === 'true' }, 'accept-cookie']">
    <div class="container">
      <div class="accept-cookie__inner">
        <div class="accept-cookie__text">
          Мы используем файлы cookie, чтобы сделать наш сайт лучше. Нажимая "ОКEY", вы принимаете Условия использования.
        </div>
        <button class="accept-cookie__button" @click="acceptCookie()">
          OKEY
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tape',
  data: function () {
    return {
      hasCookies: this.$cookies.get('accept-cookies')
    }
  },
  methods: {
    acceptCookie: function () {
      this.hasCookies = 'true'
      this.$cookies.set('accept-cookies', 'true')

      this.$cookies.get('accept-cookies')
    }
  }
}
</script>

<style lang="scss">
  .accept-cookie {
    padding: 10px 0 25px 0;

    background-color: rgba(0, 33, 69, 0.4);
    position: fixed;
    z-index: 99;
    width: 100%;
    left: 0;
    bottom: 0;

    font-size: 15px;
    font-weight: 500;
    color: #ffffff;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__button {
      background-color: rgb(0, 46, 69);
      color: #ffffff;
      padding: 6px 32px;
      margin-left: 20px;
    }

    &--hidden {
      display: none;
    }
  }
</style>
